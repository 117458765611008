export default function homeStage() {
  const navButtons = document.querySelectorAll(".home-stage__nav-button");
  const slides = document.querySelectorAll(".home-stage__slide");
  const navigation = document.querySelector(".home-stage__navigation");

  // Only proceed if there are slides and navigation buttons on the page
  if (!navButtons.length || !slides.length || !navigation) {
    return;
  }

  const pauseAllSlideVideos = () => {
    slides.forEach((slide) => {
      const video = slide.querySelector(".media__video");
      if (video) {
        if (!video.paused) {
          video.pause();
          video.currentTime = 0;
        }
      }
    });
  };

  const playActiveSlideVideo = (slideIndex) => {
    const activeSlide = slides[slideIndex];
    const activeVideo = activeSlide ? activeSlide.querySelector(".media__video") : null;

    if (activeVideo && activeVideo.paused) {
      setTimeout(() => {
        activeVideo.play().catch((error) => {
          console.error("Error attempting to play the video:", error);
        });
      }, 100);
    }
  };

  navButtons.forEach((navButton) => {
    navButton.addEventListener("click", () => {
      const slideIndex = parseInt(navButton.getAttribute("data-slide-index"), 10);

      slides.forEach((slide) => {
        slide.classList.remove("home-stage__slide--active");
      });

      navButtons.forEach((_navButton) => _navButton.classList.remove("home-stage__nav-button--active"));

      slides[slideIndex].classList.add("home-stage__slide--active");
      navButton.classList.add("home-stage__nav-button--active");

      navigation.style.setProperty("--active-index", slideIndex);

      pauseAllSlideVideos();
      playActiveSlideVideo(slideIndex);
    });
  });

  // Initial setup on load
  pauseAllSlideVideos();
  playActiveSlideVideo(0);
}
