export default function faq() {
  const faqItems = document.querySelectorAll(".faq__item");

  faqItems.forEach((item, itemIndex) => {
    item.querySelectorAll(".faq__accordion-button").forEach((button) => {
      button.addEventListener("click", () => {
        faqItems.forEach((currentItem, currentItemIndex) => {
          if (currentItemIndex !== itemIndex) {
            currentItem.classList.remove("expanded");
            document.querySelectorAll("video").forEach((video) => video.pause());
          } else {
            setTimeout(() => {
              currentItem.scrollIntoView({ behavior: "smooth", block: "start" });
            }, 250);
            if (currentItem.querySelector("video")) {
              currentItem.querySelector("video").play();
            }
          }
        });
        item.classList.toggle("expanded");
      });
    });
  });
}
