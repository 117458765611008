export default function initMediaHandlers() {
  const videoElements = document.querySelectorAll(".media__video");

  // return if no media elements are present
  if (!videoElements.length) {
    return;
  }

  videoElements.forEach((video) => {
    const videoOption = video.dataset.videoOption;
    const wrapper = video.closest(".media__video-wrapper");

    if (videoOption === "standard") {
      setupStandardVideo(video, wrapper);
    } else if (videoOption === "autoplay-sound") {
      setupAutoplaySoundVideo(video, wrapper);
    } else if (videoOption === "autoplay-only") {
      setupAutoplayOnlyVideo(video);
    }
  });

  function setupStandardVideo(video, wrapper) {
    const playButton = wrapper.querySelector(".media__video-play-btn");
    const controls = wrapper.querySelector(".media__video-controls-wrapper");
    const playPauseButton = wrapper.querySelector(".media__video-play-pause-btn");
    const progressBar = wrapper.querySelector(".media__video-progress-bar");
    const muteButton = wrapper.querySelector(".media__video-mute-btn");
    const fullscreenButton = wrapper.querySelector(".media__video-fullscreen-btn");

    let controlsTimeout = null;
    let isVideoLoaded = false;
    let hasVideoStarted = false;

    // Hide the controls initially
    controls.style.display = "none";

    const showControls = () => {
      if (!hasVideoStarted) {
        return;
      } // Do not show controls if the video hasn't started
      controls.style.display = "flex";
      clearTimeout(controlsTimeout);
      controlsTimeout = setTimeout(() => {
        controls.style.display = "none";
      }, 2000);
    };

    const loadVideo = () => {
      if (isVideoLoaded) {
        return;
      }
      isVideoLoaded = true;
      hasVideoStarted = true;

      video
        .play()
        .then(() => {
          wrapper.classList.add("media__video-wrapper--playing");
          playButton.style.display = "none"; // Hide initial play button
          controls.style.display = "flex"; // Show controls
        })
        .catch((error) => {
          console.error("Error playing video:", error);
        });
    };

    playButton.addEventListener("click", loadVideo);

    playPauseButton.addEventListener("click", () => {
      if (video.paused) {
        video
          .play()
          .then(() => {
            // Video is now playing
          })
          .catch((error) => {
            console.error("Error playing video:", error);
          });
      } else {
        video.pause();
      }
      showControls();
    });

    video.addEventListener("pause", () => {
      playPauseButton.classList.remove("js-pause");
      playPauseButton.classList.add("js-play");
      playPauseButton.querySelector(".icon").innerHTML =
        "<path d='M39.2656 24.6328V23.4609L9.00781 6L8 6.58594V41.5078L9.00781 42.0938L39.2656 24.6328Z' fill='currentColor'/>"; // Play icon SVG
    });

    video.addEventListener("playing", () => {
      playPauseButton.classList.remove("js-play");
      playPauseButton.classList.add("js-pause");
      playPauseButton.querySelector(".icon").innerHTML =
        "<path d='M12 10h8v28h-8V10Zm16 0h8v28h-8V10Z' fill='currentColor'/>"; // Pause icon SVG
    });

    video.addEventListener("timeupdate", () => {
      const value = (video.currentTime / video.duration) * 100;
      progressBar.style.setProperty("--progress", `${value}%`);
    });

    progressBar.addEventListener("input", () => {
      const newTime = video.duration * (progressBar.value / 100);
      video.currentTime = newTime;
      showControls();
    });

    if (muteButton) {
      muteButton.addEventListener("click", () => {
        video.muted = !video.muted;

        muteButton.querySelector(".icon").innerHTML = video.muted
          ? `<path d="m41 45.2-6.6-6.6a17.344 17.344 0 0 1-3.025 1.725A18.239 18.239 0 0 1 28 41.45v-3.1a29.074 29.074 0 0 0 2.225-.775 8.945 8.945 0 0 0 2.025-1.125L24 28.15V40L14 30H6V18h7.8L2.8 7l2.15-2.15L43.15 43 41 45.2Zm-1.8-11.6-2.15-2.15a13.544 13.544 0 0 0 1.475-3.6c.317-1.267.475-2.567.475-3.9 0-3.434-1-6.509-3-9.225-2-2.717-4.667-4.442-8-5.175v-3.1c4.133.933 7.5 3.025 10.1 6.275 2.6 3.25 3.9 6.991 3.9 11.225 0 1.7-.233 3.366-.7 5a17.664 17.664 0 0 1-2.1 4.65Zm-6.7-6.7L28 22.4v-6.5a8.301 8.301 0 0 1 3.675 3.3A9.133 9.133 0 0 1 33 24a8.543 8.543 0 0 1-.5 2.9ZM24 18.4l-5.2-5.2L24 8v10.4Z"  fill="currentColor" />` // Mute icon SVG
          : `<path d="M28 41.45v-3.1c3.233-.933 5.875-2.725 7.925-5.375S39 27.317 39 23.95c0-3.366-1.017-6.383-3.05-9.05-2.033-2.667-4.683-4.45-7.95-5.35v-3.1c4.133.934 7.5 3.025 10.1 6.275 2.6 3.25 3.9 6.992 3.9 11.225s-1.3 7.975-3.9 11.225c-2.6 3.25-5.967 5.342-10.1 6.275ZM6 30V18h8L24 8v32L14 30H6Zm21 2.4V15.55c1.833.567 3.292 1.633 4.375 3.2C32.458 20.317 33 22.067 33 24c0 1.9-.55 3.634-1.65 5.2-1.1 1.567-2.55 2.634-4.35 3.2Z" fill="currentColor"/>`; // Unmute icon SVG

        showControls();
      });
    }

    function isIOS() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }

    if (isIOS()) {
      video.removeAttribute("playsinline");
    }

    fullscreenButton.addEventListener("click", () => {
      if (isIOS()) {
        // For iOS, rely on the native fullscreen behavior when playing
        video.play();
      } else {
        // For other platforms, use the standard fullscreen API
        if (video.requestFullscreen) {
          video.requestFullscreen();
        } else if (video.webkitRequestFullscreen) {
          video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) {
          video.msRequestFullscreen();
        } else if (video.mozRequestFullScreen) {
          video.mozRequestFullScreen();
        } else {
          console.error("Fullscreen API is not supported.");
        }
      }
    });

    video.addEventListener("volumechange", () => {
      // Volume change event handling
    });

    wrapper.addEventListener("mousemove", showControls);
  }

  function setupAutoplaySoundVideo(video, wrapper) {
    const soundButton = wrapper.querySelector(".media__video-sound-btn");
    const iconSpan = soundButton.querySelector(".button__icon");

    const handleMuteUnmute = () => {
      video.muted = !video.muted;

      iconSpan.innerHTML = video.muted
        ? `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
             <path d="M16.5 12C16.5 10.23 15.48 8.70999 14 7.96999V10.18L16.45 12.63C16.48 12.43 16.5 12.22 16.5 12ZM19 12C19 12.94 18.8 13.82 18.46 14.64L19.97 16.15C20.63 14.91 21 13.5 21 12C21 7.72 18.01 4.14 14 3.23V5.29C16.89 6.15 19 8.83 19 12ZM4.27 3L3 4.27L7.73 9H3V15H7L12 20V13.27L16.25 17.52C15.58 18.04 14.83 18.45 14 18.7V20.76C15.38 20.45 16.63 19.81 17.69 18.95L19.73 21L21 19.73L12 10.73L4.27 3ZM12 4L9.91 6.09L12 8.18V4Z" fill="currentColor"/>
           </svg>`
        : `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
             <path d="M3 9.99999V14C3 14.55 3.45 15 4 15H7L10.29 18.29C10.92 18.92 12 18.47 12 17.58V6.40999C12 5.51999 10.92 5.06999 10.29 5.69999L7 8.99999H4C3.45 8.99999 3 9.44999 3 9.99999ZM16.5 12C16.5 10.23 15.48 8.70999 14 7.96999V16.02C15.48 15.29 16.5 13.77 16.5 12ZM14 4.44999V4.64999C14 5.02999 14.25 5.35999 14.6 5.49999C17.18 6.52999 19 9.05999 19 12C19 14.94 17.18 17.47 14.6 18.5C14.24 18.64 14 18.97 14 19.35V19.55C14 20.18 14.63 20.62 15.21 20.4C18.6 19.11 21 15.84 21 12C21 8.15999 18.6 4.88999 15.21 3.59999C14.63 3.36999 14 3.81999 14 4.44999Z" fill="currentColor"/>
           </svg>`;
    };

    soundButton.addEventListener("click", handleMuteUnmute);
  }

  function setupAutoplayOnlyVideo(video) {
    video.play().catch((error) => console.error("Error playing video:", error));
  }
}
