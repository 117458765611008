import cookieBar from "./cookiebar";
import accordion from "../../site/plugins/custom-components/src/blocks/accordion/accordion.js";
import team from "../../site/plugins/custom-components/src/blocks/team/team.js";
import homeStage from "../../site/plugins/custom-components/src/blocks/home-stage/home-stage.js";
import mediaToggle from "../../site/plugins/custom-components/src/blocks/media-toggle/media-toggle.js";
import clusteredCollection from "../../site/plugins/custom-components/src/blocks/clustered-collection/clustered-collection.js";
import mediaReel from "../../site/plugins/custom-components/src/blocks/media-reel/media-reel.js";
import faq from "../../site/plugins/custom-components/src/blocks/faq/faq.js";
import openAndCloseNavigationOverlay from "./open-and-close-navigation-overlay";
import showAndHideDropdownMenuItemsOnHover from "./show-and-hide-dropdown-menu-items-on-hover";
import showNavigationWhenScrollingUp from "./show-navigation-when-scrolling-up";
import videoOverlay from "./video-overlay";
import newsBanner from "./newsBanner";
import newsletterSignup from "./newsletter-signup";
import initMediaHandlers from "./media-handler";

const detectSafari = () => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  if (isSafari) {
    document.body.classList.add("safari");
  }
};

document.addEventListener("DOMContentLoaded", () => {
  detectSafari();
  openAndCloseNavigationOverlay();
  showAndHideDropdownMenuItemsOnHover();
  showNavigationWhenScrollingUp();
  accordion();
  team();
  videoOverlay();
  homeStage();
  cookieBar();
  newsBanner();
  newsletterSignup();
  mediaToggle();
  clusteredCollection();
  mediaReel();
  initMediaHandlers();
  faq();
});
